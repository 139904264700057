@font-face {
  font-family: 'habstreak';
  src: url('../font/habstreak.eot?17957860');
  src: url('../font/habstreak.eot?17957860#iefix') format('embedded-opentype'),
       url('../font/habstreak.woff2?17957860') format('woff2'),
       url('../font/habstreak.woff?17957860') format('woff'),
       url('../font/habstreak.ttf?17957860') format('truetype'),
       url('../font/habstreak.svg?17957860#habstreak') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'habstreak';
    src: url('../font/habstreak.svg?17957860#habstreak') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "habstreak";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-check-circle:before { content: '\e800'; } /* '' */
.icon-email:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-google:before { content: '\e803'; } /* '' */
.icon-person:before { content: '\e804'; } /* '' */
.icon-twitter:before { content: '\e805'; } /* '' */
.icon-instagram:before { content: '\e806'; } /* '' */
.icon-lock:before { content: '\e807'; } /* '' */
.icon-linkedin:before { content: '\e808'; } /* '' */
.icon-language:before { content: '\e809'; } /* '' */
.icon-paragliding:before { content: '\e80a'; } /* '' */
.icon-reward:before { content: '\e80b'; } /* '' */
.icon-scuba-diving:before { content: '\e80c'; } /* '' */
.icon-search:before { content: '\e80d'; } /* '' */
.icon-skiing:before { content: '\e80e'; } /* '' */
.icon-streak:before { content: '\e80f'; } /* '' */
.icon-surfing:before { content: '\e810'; } /* '' */
.icon-heart:before { content: '\e811'; } /* '' */
.icon-back:before { content: '\e812'; } /* '' */
.icon-calendar:before { content: '\e813'; } /* '' */
.icon-close:before { content: '\e814'; } /* '' */
.icon-delete:before { content: '\e815'; } /* '' */
.icon-edit:before { content: '\e816'; } /* '' */
.icon-expand-less:before { content: '\e817'; } /* '' */
.icon-expand-more:before { content: '\e818'; } /* '' */
.icon-filter:before { content: '\e819'; } /* '' */
.icon-flag:before { content: '\e81a'; } /* '' */
.icon-going-in:before { content: '\e81b'; } /* '' */
.icon-grid-view:before { content: '\e81c'; } /* '' */
.icon-home:before { content: '\e81d'; } /* '' */
.icon-kayaking:before { content: '\e81e'; } /* '' */
.icon-list-view:before { content: '\e81f'; } /* '' */
.icon-menu:before { content: '\e820'; } /* '' */
.icon-notifications:before { content: '\e821'; } /* '' */
.icon-activity:before { content: '\e822'; } /* '' */
.icon-logout:before { content: '\e823'; } /* '' */
.icon-close-circle:before { content: '\e824'; } /* '' */
.icon-play:before { content: '\e825'; } /* '' */
.icon-fullscreen:before { content: '\e826'; } /* '' */
.icon-fullscreen-exit:before { content: '\e827'; } /* '' */
.icon-pause:before { content: '\e828'; } /* '' */
.icon-clone:before { content: '\e829'; } /* '' */
